<template>
  <b-card>
    <h3 class="mb-3">
      Materiały dodatkowe
    </h3>
    <div
      v-for="category in categories"
      :key="category"
      class="d-inline-block mr-1 mb-5"
    >
      <b-badge
        pill
        class="clickable"
        variant="primary"
      >
        {{ category }}
      </b-badge>
    </div>

    <b-list-group v-if="additionalMaterials.length > 0">
      <b-list-group-item
        v-for="item in additionalMaterials"
        :key="item.id"
        class="flex-column align-items-start"
        style="margin-bottom: 10px;border-top-width: 1px"
      >
        <a
          href="#"
          class="d-flex flex-column  w-100 justify-content-between"
        >
          <p class="media-heading mb-0">
            <span
              class="font-weight-normal"
              style="color: #666;"
            >
              {{ item.title }}
            </span>
          </p>
        </a>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BBadge,
  BCard, BCardText, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { useEcommerceUi } from '@/views/shop/useEcommerce'
import additionalMaterials from '@/views/AdditionalMaterials.vue'

export default {
  components: {
    BBadge,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      categories: [
        'Arkusze maturalne',
        'Nagrania dodatkowe (YT)',
      ],
      additionalMaterials: [
        { id: 0, title: '💙Arkusz CKE maj 2023 z komentarzami egzaminatora maturalnego' },
        { id: 1, title: '💙Arkusz CKE grudzień 2022 z komentarzami egzaminatora maturalnego' },
        { id: 2, title: 'Budowa atomu (YT)' },
      ],
    }
  },
  computed: {
  },
  created() {
    this.fetchDiscounts()
  },
  methods: {
    fetchDiscounts() {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/discounts`).then(response => {
        this.discounts = response.data.discounts
      })
    },
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    return {
      toggleProductInWishlist,
      handleCartActionClick,
    }
  },
}
</script>

<style>

</style>
